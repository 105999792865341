body{
  background-color: #e8e9d8;
}
#topNavbar{
    padding: 3px 0px;
    background-color: #003c71;
}
/* #logobsci{
    height: 40px;
} */
#contentCenter{
    /*
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
    width: 100px;
    height: 100px;
    */
    text-align: left;
}
.inputnoBackground{
    background-color: transparent;
}
.checkbox {
    background-color: #e8e9d8;
    border: solid 1px #303030;
    border-radius: 4px;
    cursor: pointer;
    display: inline-block;
    height: 30px;
    margin-right: 1rem;
    text-align: center;
    width: 30px;
    vertical-align: middle;
    z-index: 3!important;
    position: sticky;
    
  }
  /*
  svg {
    margin-top: 6px;
    stroke: #303030;
    stroke-width: 10%;
    z-index: 2!important;
    position: sticky;
  }
  
  path{
    z-index: 2!important;
    position: sticky;
  }
  */
  .positive {
    background-color: #e8e9d8;
    border-color: #303030;
  }
  
  .negative {
    background-color: #e8e9d8;
    border-color: #303030;
  }
  
  .label {
    font-weight: bold;
    vertical-align: middle;
  }


  .st0{fill:#FF4B57;}
	.st1{fill:#FFFFFF;}
	.st2{display:block;}
	.st3{display:inline;}
	.st4{fill:#7BE5F3;}
	.st5{display:inline;fill:#FFFFFF;}
	.st6{display:inline;fill:#555555;}
	.st7{/*font-family:'SegoeUI-Semibold';*/ font-weight: bold;}
	.st8{font-size:10.0698px;}
	.st9{fill:#2A2A2B;}
	.st10{fill:#555555;}
	.st11{fill:none;stroke:#FFFFFF;stroke-miterlimit:10;}
	.st12{fill:#7BE5F3;stroke:#FFFFFF;stroke-miterlimit:10;}
	.st13{display:block;fill:#555555;stroke:#FFFFFF;stroke-miterlimit:10;}
  .st14{fill:#555555;stroke:#FFFFFF;stroke-miterlimit:10;}
  .stsvg{enable-background: new 0 0 130.49 471.64;}

  .btnSel{background-color: #d141f0;}

  .stcir{fill:none;stroke:#ffffff;stroke-width:2;stroke-miterlimit:10;}
  .strokeBlanco{stroke:#ffffff}
  .strokeBlack{stroke:#000000!important}
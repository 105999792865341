body{
    background-color: #e8e9d8;
}
#topNavbar{
    padding: 3px 0px;
    background-color: #003c71;
}
#topNavbarProgress, #topNavbarProgress .ms-ProgressIndicator-itemProgress{
  padding: 0px;
}
/* #logobsci{
    height: 40px;
} */
#contentCenter{
    /*
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
    width: 100px;
    height: 100px;
    */
    text-align: left;
}
.inputnoBackground{
    background-color: transparent;
}


:local(.checkbox) {
  background-color: #e8e9d8;
  border: solid 1px #303030;
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
  height: 30px;
  margin-right: 1rem;
  text-align: center;
  width: 30px;
  vertical-align: middle;
  z-index: 3!important;
  position: sticky;
  
}
/*
svg {
  margin-top: 6px;
  stroke: #303030;
  stroke-width: 10%;
  z-index: 2!important;
  position: sticky;
}
path{
  z-index: 2!important;
  position: sticky;
}
*/
.positive {
  background-color: #e8e9d8;
  border-color: #303030;
}

.negative {
  background-color: #e8e9d8;
  border-color: #303030;
}

.label {
  font-weight: bold;
  vertical-align: middle;
}


.btn-p0{
	background-color: #0078d4;
	}
.btn-p0:hover{
	background-color: #0078d4;
	}
.centrar-p0{
	text-align:center;
	}

.nameErr{
		color:#ffc548
	}

.P3-btn2Err : {
	background-color: #cacaca;
	color: #d141f0;
	display:inline-block;
	}
.P3-btn2Err{
	background-color: #d141f0;
	color: #cacaca;
	display:inline-block;
	
	}
.btn-container{
	margin-top: 6%;
	text-align:center;
	}

.imgP3{
	text-align:center;
	margin-top:3%!important;
}
	
.P3-botton-p{
	padding-left:0px!important;
	padding.right:0px!important;
	}
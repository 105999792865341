

.checkboxBlack {
  background-color: #383838 !important;
  border: solid 1px #ffffff !important;
  border-radius: 4px!important;
  cursor: pointer;
  display: inline-block!important;
  height: 30px!important;
  margin-right: 1rem!important;
  text-align: center!important;
  width: 30px!important;
  vertical-align: middle!important;
  z-index: 3!important;
  position: sticky!important;
}

.checkboxBlack > svg {
  margin-top: 6px!important;
  stroke: #ffffff!important;
  stroke-width: 10%!important;
  z-index: 2 !important;
  position: sticky!important;
}
.redNegative{
  stroke: #ff4b57!important;
}
.greenPositive{
  stroke: #7be5f3!important;
}
.whiteNeutral{
  stroke: rgb(255, 255, 255)!important;
}
path{
  z-index: 2 !important;
  position: sticky!important;
}

.checkboxBlack .positive {
  background-color: #383838!important;
  border-color: #ffffff!important;
}

.checkboxBlack .negative {
  background-color: #383838!important;
  border-color: #ffffff!important;
}

.label {
  font-weight: bold!important;
  vertical-align: middle!important;
}

